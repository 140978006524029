import { render, staticRenderFns } from "./TaskDeskCustomFilter.vue?vue&type=template&id=810ca5e6&scoped=true&"
import script from "./TaskDeskCustomFilter.vue?vue&type=script&lang=js&"
export * from "./TaskDeskCustomFilter.vue?vue&type=script&lang=js&"
import style0 from "./TaskDeskCustomFilter.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./TaskDeskCustomFilter.vue?vue&type=style&index=1&id=810ca5e6&lang=scss&scoped=true&"
import style2 from "./TaskDeskCustomFilter.vue?vue&type=style&index=2&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "810ca5e6",
  null
  
)

export default component.exports
import {QBtnDropdown,QIcon,QChip,QBtn,QTabs,QTab,QSeparator,QTabPanels,QTabPanel,QSelect,QItem,QItemSection,QCheckbox,QItemLabel,QBtnToggle,QList,Ripple} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QBtnDropdown,QIcon,QChip,QBtn,QTabs,QTab,QSeparator,QTabPanels,QTabPanel,QSelect,QItem,QItemSection,QCheckbox,QItemLabel,QBtnToggle,QList})
component.options.directives = Object.assign(Object.create(component.options.directives || null), component.options.directives || {}, {Ripple})

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Fuse from 'fuse.js'
import { date as quasarDate } from 'quasar'

import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css'
import BaseSpinner from '@/components/UI/BaseSpinner.vue'
import {
  tagsStore,
  sectionsStore,
  contactsStore,
  uiStore,
} from '@/store'
import { getDefaultFuseOptions } from '@/utils'
import { multiSearch } from '@/utils/Common'
import BaseEntityAvatar from '@/components/UI/BaseEntityAvatar.vue'
import { mapGetters } from 'vuex'

const {
  formatDate,
  addToDate,
  startOfDate,
  endOfDate,
  getDateDiff,
  isSameDate,
  getDayOfWeek,
} = quasarDate

export default {
  components: {
    VueCtkDateTimePicker: () => ({
      component: import('vue-ctk-date-time-picker'),
      loading: BaseSpinner,
    }),
    BaseEntityAvatar,
  },
  data () {
    return {
      fuseTagsOptions: getDefaultFuseOptions(['name']),
      fuseContactsOptions: getDefaultFuseOptions(['displayName']),
      fuseProjectsOptions: getDefaultFuseOptions(['name']),
      fuseDepartmentsOptions: getDefaultFuseOptions(['name']),
      deadlineType: 'deadline',
      date: {
        start: null,
        end: null,
      },
      tab: 'project',
      icons: {
        project: 'fas fa-columns',
        deadline: 'far fa-calendar-alt',
        assignee: 'fas fa-user',
        owner: 'fas fa-user-edit',
        tag: 'fas fa-hashtag',
        department: 'fas fa-sitemap',
      },

      // ASSIGNEE STUFF
      assigneeInput: '',
      filteredAssigneeList: [],
      selectedAssignees: [],
      oldSelectedAssignees: [],

      // OWNER STUFF
      ownerInput: '',
      filteredOwnerList: [],
      selectedOwners: [],

      // TAGS STUFF
      tagsInput: '',
      filteredTagList: [],
      selectedTags: [],

      // PROJECT STUFF
      projectsInput: '',
      filteredProjectList: [],
      selectedProjects: [],

      // DEPARTMENTS STUFF
      departmentsInput: '',
      filteredDepartmentList: [],
      selectedDepartments: [],
    }
  },
  computed: {
    ...mapGetters([
      'profileId',
      'profile',
    ]),
    tabs () {
      return [
        {
          name: 'project',
          label: this.$t('dashboard.viewOptions.byProject'),
          icon: 'fas fa-columns',
        },
        {
          name: 'deadline',
          label: this.$t('tasks.deadline.basic'),
          icon: 'far fa-calendar-alt',
        },
        {
          name: 'assignee',
          label: this.$t('dashboard.viewOptions.byAssignee'),
          icon: 'fas fa-user',
        },
        {
          name: 'owner',
          label: this.$t('dashboard.viewOptions.byOwner'),
          icon: 'fas fa-user-edit',
        },
        {
          name: 'tag',
          label: this.$t('dashboard.viewOptions.byTag'),
          icon: 'fas fa-hashtag',
        },
        {
          name: 'department',
          label: this.$t('dashboard.viewOptions.byDepartment'),
          icon: 'fas fa-sitemap',
        },
      ]
    },
    customShortcuts () {
      return [
        {
          key: 'today',
          label: this.$t('common.today'),
          value: () => {
            return {
              start: startOfDate(Date(), 'day'),
              end: endOfDate(Date(), 'day'),
            }
          },
        },
        {
          key: 'thisWeek',
          label: this.$t('dashboard.thisWeek'),
          value: () => {
            const date = new Date()
            const dayOfWeek = getDayOfWeek(date)
            const days = dayOfWeek === 7 ? 0 : -dayOfWeek

            return {
              start: addToDate(date, { days: days + 1 }),
              end: addToDate(date, { days: days + 7 }),
            }
          },
        },
        {
          key: 'nextWeek',
          label: this.$t('dashboard.nextWeek'),
          value: () => {
            const date = new Date()
            const dayOfWeek = getDayOfWeek(date)
            const days = dayOfWeek === 7 ? 7 : 7 - dayOfWeek

            return {
              start: addToDate(date, { days: days + 1 }),
              end: addToDate(date, { days: days + 7 }),
            }
          },
        },
        {
          key: 'thisMonth',
          label: this.$t('dashboard.thisMonth'),
          value: () => {
            return {
              start: startOfDate(Date(), 'month'),
              end: endOfDate(Date(), 'month'),
            }
          },
        },
        {
          key: 'nextMonth',
          label: this.$t('dashboard.nextMonth'),
          value: () => {
            const date = addToDate(new Date(), { month: 1 })
            return {
              start: startOfDate(date, 'month'),
              end: endOfDate(date, 'month'),
            }
          },
        },
      ]
    },
    deadlineTypeOptions () {
      return [
        { label: this.$t('tasks.deadline.basic'), value: 'deadline' },
        { label: this.$t('tasks.created'), value: 'created' },
        { label: this.$t('dashboard.finished'), value: 'done' },
      ]
    },
    fuseForProjects () {
      return new Fuse(this.projectList, this.fuseProjectsOptions)
    },
    fuseForContacts () {
      return new Fuse(this.contactList, this.fuseContactsOptions)
    },
    fuseForTags () {
      return new Fuse(this.tagList, this.fuseTagsOptions)
    },
    fuseForDepartments () {
      return new Fuse(this.departmentList, this.fuseDepartmentsOptions)
    },
    selectedOptions () {
      const displayOptions = []

      this.selectedAssignees.forEach(contact => {
        displayOptions.push({
          id: contact.jid,
          label: contact.displayName,
          type: 'assignee',
        })
      })

      this.selectedOwners.forEach(contact => {
        displayOptions.push({
          id: contact.jid,
          label: contact.displayName,
          type: 'owner',
        })
      })

      this.selectedTags.forEach(tag => {
        displayOptions.push({
          id: tag.uid,
          label: tag.name,
          type: 'tag',
        })
      })

      this.selectedProjects.forEach(project => {
        displayOptions.push({
          id: project.uid,
          label: project.name,
          type: 'project',
        })
      })

      this.selectedDepartments.forEach(item => {
        displayOptions.push({
          id: item.uid,
          label: item.name,
          type: 'department',
        })
      })

      const now = new Date()

      // TODO: refactor and localize where possible and needed
      const formatDeadline = timestamp => {
        const d = new Date(timestamp)
        let result
        if (getDateDiff(d, now, 'days') < 1) {
          result = this.$t('tasks.deadline.today')
        } else if (getDateDiff(d, now, 'days') < 2) {
          result = this.$t('tasks.deadline.tomorrow')
        } else if (isSameDate(d, now, 'year')) {
          return formatDate(d, 'D MMM')
        } else {
          if (this.lang === 'ru') {
            return formatDate(d, 'D MMM YYYY')
          } else {
            return formatDate(d, 'MMM D, YYYY')
          }
        }
        return result
      }

      const getFormattedDeadline = (start, end) => {
        const s = new Date(start)
        const e = new Date(end)

        if (isSameDate(s, e, 'day')) {
          return formatDeadline(s)
        } else {
          return ''.concat(formatDeadline(s), ' - ', formatDeadline(e))
        }
      }

      this.dateStart && this.dateEnd && displayOptions.push({
        label: getFormattedDeadline(this.dateStart, this.dateEnd),
        type: 'deadline',
      })

      return displayOptions
    },
    dateSpecified () {
      return this.date && this.date.start
    },
    dateStart () {
      return this.dateSpecified
        ? formatDate(this.date.start, 'YYYY-MM-DDTHH:mm:ss.SSSZ')
        : null
    },
    dateEnd () {
      const fDate = date => {
        const endDate = endOfDate(date, 'day')
        return formatDate(endDate, 'YYYY-MM-DDTHH:mm:ss.SSSZ')
      }

      // if no end date specified - take start date
      return this.dateSpecified
        ? fDate(this.date.end || this.date.start)
        : null
    },
    contactList () {
      let contactList = [...contactsStore.getters.contactList]
      contactList.push(this.$store.getters.profile)

      // only leave what's necessary
      contactList = contactList.map(contact => {
        return {
          displayName: contact.displayName,
          jid: contact.jid,
          isArchive: contact.isArchive,
        }
      })

      contactList.sort((a, b) => {
        if (a.isArchive !== b.isArchive) return a.isArchive ? 1 : -1
        return a.displayName > b.displayName ? 1 : -1
      })

      return contactList
    },
    tagList () {
      const tagList = [...tagsStore.state.tags]
      tagList.sort((a, b) => a.name > b.name ? 1 : -1)
      return tagList
    },
    projectList () {
      return Object.values(sectionsStore.state.task)
        .filter(p => !p.isArchive)
        .map(p => ({
          name: p.name,
          uid: p.uid,
        }))
    },
    departmentList () {
      return [...sectionsStore.getters.contactSectionsSorted()]
    },
    lang () {
      return this.$i18n.locale.substr(0, 2)
    },
    isMondayFirst () {
      return this.lang === 'ru-RU' || this.lang === 'ru'
    },
    hasSelected () {
      return this.selectedOptions.length > 0
    },
    isAssigneeMe () {
      return this.selectedAssignees &&
        this.selectedAssignees.length === 1 &&
        this.selectedAssignees.some(assignee => assignee.jid === this.profileId)
    },
  },
  async created () {
    // ! DEBT: ugly. refactor
    const lastUsedFilters = uiStore.getters.currentDeskFilters
    this.selectedTags = lastUsedFilters.tag.map(tagName => this.tagList.find(tag => tag.name === tagName))
    // strips off unnecessary stuff from contact object
    // const contactMapper = (jid) => {
    //   const contact = this.$store.getters.contact(jid)
    //   return {
    //     displayName: contact.displayName,
    //     jid: contact.jid,
    //     icon: contact.icon
    //   }
    // }
    this.selectedAssignees = uiStore.getters.currentDeskFilters.assignee.map(jid => this.contactList.find(contact => contact.jid === jid))
    this.selectedOwners = uiStore.getters.currentDeskFilters.owner.map(jid => this.contactList.find(contact => contact.jid === jid))
    this.setSelectedProjects(uiStore.getters.currentDeskFilters.section.map(uid => this.projectList.find(project => project.uid === uid)))
    this.selectedDepartments = uiStore.getters.currentDeskFilters.department.map(uid => this.departmentList.find(item => item.uid === uid))
    const deadlineTypeOptions = ['created_gte', 'created_lte', 'deadline_gte', 'deadline_lte', 'done_gte', 'done_lte']
    const existingDeadline = deadlineTypeOptions.find(deadlineType => lastUsedFilters[deadlineType] !== null)
    if (existingDeadline) {
      this.deadlineType = existingDeadline.slice(0, -4)
      this.date.start = lastUsedFilters[this.deadlineType.concat('_gte')]
      this.date.end = lastUsedFilters[this.deadlineType.concat('_lte')]
    }
  },
  methods: {
    setSelectedProjects (projects) {
      const options = this.projectList.map(p => p.uid)

      this.selectedProjects = projects.filter(p => {
        return options.includes(p.uid)
      })
    },
    clearFilters () {
      this.sendGoal('Очистить фильтр')

      this.date.start = null
      this.date.end = null
      this.selectedAssignees = []
      this.selectedOwners = []
      this.selectedTags = []
      this.setSelectedProjects()
      this.selectedProjects = []
      this.selectedDepartments = []
      const filterOptions = {
        ...this.getDeadlineFilterOptions(),
        assignee: [],
        owner: [],
        section: [],
        tag: [],
        department: [],
      }
      this.setNewFilter(filterOptions)
    },
    tagSearch (text) {
      return multiSearch(text => this.fuseForTags.search(text), text, 'name')
    },
    contactSearch (text) {
      return multiSearch(text => this.fuseForContacts.search(text), text)
    },
    projectSearch (text) {
      return multiSearch(text => this.fuseForProjects.search(text), text, 'uid')
    },
    departmentSearch (text) {
      return multiSearch(text => this.fuseForDepartments.search(text), text, 'uid')
    },
    showPopup (ref) {
      // small timeout to let animation finish. quasar-vue-plugin seems
      // to not support @transition event on q-tab-panels. need quasar-cli for that
      setTimeout(() => {
        this.$refs[ref].showPopup()
      }, 300)
    },
    /**
     * Setting new filter by dashboard
     * @param filter
     * @return Promise<void>
     */
    setNewFilter (filter) {
      const newFilters = Object.assign({}, uiStore.getters.currentDeskFilters, filter)
      uiStore.actions.taskDeskSetFilters(newFilters)
    },
    contactFilterFunction (userInput, update) {
      const val = userInput.trim()

      // filter only starting with second character
      update(() => {
        this.filteredAssigneeList = val.length < 2
          ? this.contactList
          : this.contactSearch(val).sort(this.sortArchivedContacts)
      })
    },
    ownerFilterFunction (userInput, update) {
      const val = userInput.trim()

      // filter only starting with second character
      update(() => {
        this.filteredOwnerList = val.length < 2
          ? this.contactList
          : this.contactSearch(val).sort(this.sortArchivedContacts)
      })
    },
    tagFilterFunction (userInput, update) {
      const val = userInput.trim()

      // filter only starting with second character
      update(() => {
        this.filteredTagList = val.length < 2
          ? this.tagList
          : this.tagSearch(val)
      })
    },
    projectFilterFunction (userInput, update) {
      const val = userInput.trim()

      // filter only starting with second character
      update(() => {
        this.filteredProjectList = val.length < 2
          ? this.projectList
          : this.projectSearch(val)

        this.setSelectedProjects(this.selectedProjects)
      })
    },
    departmentFilterFunction (userInput, update) {
      const val = userInput.trim()

      // filter only starting with second character
      update(() => {
        this.filteredDepartmentList = val.length < 2
          ? this.departmentList
          : this.departmentSearch(val)
      })
    },
    sortArchivedContacts (a, b) {
      if (a.isArchive === b.isArchive) return 0
      return a.isArchive ? 1 : -1
    },
    /**
     * Clears the input field from the search string after selection item
     * @param inputName assignee | owner | project | tag the name of the reference to be cleaned
     */
    clearInput (inputName) {
      this.$refs[inputName] && this.$refs[inputName].updateInputValue('', true)
    },
    /**
     * Choosing assignees in the filter
     * @param assignees array of assignees
     */
    async selectAssignees (assignees) {
      if (assignees) this.sendGoal('ВКЛ «Фильтр по Исполнителю»')

      this.selectedAssignees = assignees
      const assignee = assignees.map(assigne => assigne.jid)
      await this.setNewFilter({ assignee })
      this.clearInput('assignee')
    },
    /**
     * Choosing owners in the filter
     * @param owners array of owners
     */
    async selectOwners (owners) {
      if (owners) this.sendGoal('ВКЛ «Фильтр по Постановщику»')

      this.selectedOwners = owners
      const owner = owners.map(owner => owner.jid)
      await this.setNewFilter({ owner })
      this.clearInput('owner')
    },
    /**
     * Choosing projects in the filter
     * @param projects array of projects
     */
    async selectProjects (projects) {
      if (projects) this.sendGoal('ВКЛ «Фильтр по Проекту»')

      this.selectedProjects = projects
      const section = projects.map(project => project.uid)
      await this.setNewFilter({ section })
      this.clearInput('project')
    },
    /**
     * Choosing tags in the filter
     * @param tags array of tags
     */
    async selectTags (tags) {
      if (tags) this.sendGoal('ВКЛ «Фильтр по Тегу»')

      this.selectedTags = tags
      const tag = tags.map(tag => tag.name)
      await this.setNewFilter({ tag })
      this.clearInput('tag')
    },
    async selectDepartments (items) {
      if (items) this.sendGoal('ВКЛ «Фильтр по Отделу»')

      this.selectedDepartments = items
      const department = items.map(item => item.uid)
      await this.setNewFilter({ department })
      this.clearInput('department')
    },
    /**
     * Choosing deadline type in the filter
     * @param deadlineType
     */
    selectDeadlineType (deadlineType) {
      const filterOptions = this.getDeadlineFilterOptions()
      const keyStart = `${deadlineType}_gte`
      const keyEnd = `${deadlineType}_lte`
      filterOptions[keyStart] = formatDate(this.dateStart, 'YYYY-MM-DDTHH:mm:ss.SSSZ')
      filterOptions[keyEnd] = formatDate(this.dateEnd, 'YYYY-MM-DDTHH:mm:ss.SSSZ')
      this.setNewFilter(filterOptions)
    },
    /**
     * Change filter date handler
     * @param date
     */
    changeDate (date) {
      this.sendGoal('ВКЛ «Фильтр по Сроку»')

      this.date = {
        start: formatDate(date.start, 'YYYY-MM-DD'),
        end: formatDate(date.end, 'YYYY-MM-DD') || null,
      }

      const filter = {}
      const keyEnd = `${this.deadlineType}_lte`
      const keyStart = `${this.deadlineType}_gte`
      filter[keyStart] = formatDate(date.start, 'YYYY-MM-DDTHH:mm:ss.SSSZ')
      filter[keyEnd] = formatDate(date.end, 'YYYY-MM-DDTHH:mm:ss.SSSZ')
      this.setNewFilter(filter)
    },
    getDeadlineFilterOptions () {
      return {
        created_gte: null,
        created_lte: null,
        done_gte: null,
        done_lte: null,
        deadline_gte: null,
        deadline_lte: null,
      }
    },
    removeOption ({ id, type }) {
      switch (type) {
        case 'assignee':
          return this.selectAssignees(this.selectedAssignees.filter(item => item.jid !== id))
        case 'owner':
          return this.selectOwners(this.selectedOwners.filter(item => item.jid !== id))
        case 'project':
          return this.selectProjects(this.selectedProjects.filter(item => item.uid !== id))
        case 'tag':
          return this.selectTags(this.selectedTags.filter(item => item.uid !== id))
        case 'department':
          return this.selectDepartments(this.selectedDepartments.filter(item => item.uid !== id))
        case 'deadline':
          this.setNewFilter(this.getDeadlineFilterOptions())
          this.date.start = null
          this.date.end = null
      }
    },
    setAssigneeMe (bool) {
      this.sendGoal(bool ? 'ВКЛ «Я исполнитель»' : 'ВЫКЛ «Я исполнитель»')

      if (!bool) {
        this.selectAssignees(this.oldSelectedAssignees)
        return
      }

      if (this.selectedAssignees.length > 0 && !this.isAssigneeMe) {
        this.oldSelectedAssignees = this.selectedAssignees
      } else {
        this.oldSelectedAssignees = []
      }

      this.selectAssignees([{
        displayName: this.profile.displayName,
        jid: this.profile.jid,
        icon: this.profile.icon,
      }])
    },
    sendGoal (msg) {
      window.goal('dashboardControls', { dashboardControls: msg })
    },
  },
}
